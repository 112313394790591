import { Injectable } from '@angular/core';
import { signIn, signOut, getCurrentUser, fetchAuthSession, AuthUser, updateUserAttribute, SignInOutput, confirmSignIn } from 'aws-amplify/auth';
import { BehaviorSubject, from, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';
import { APOLLO_USER_GROUPS_KEYS, GenericResponse, UserTypes } from 'src/app/shared/models/global';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { IdleService } from 'src/app/shared/services/timeout/idle.service';
import { getArrayMatchingValues } from 'src/app/shared/utils/array';
import { userPermissions } from '../../users/services/users/users.service';

type loginInput = {
  username: string;
  password?: string;
}

type updateUserAttributeInput = {
  attributeKey: string;
  value: string;
}


export interface CurrentUserInfo {
  attributes: { [key: string]: any };
  id: string;
  username: string
}

export interface AuthState {
  isLoggedIn: boolean;
  id?: string;
  username?: string;
  groups?: APOLLO_USER_GROUPS_KEYS[];
  // permissions?: APOLLO_USER_GROUPS_KEYS[]; // Permissions is solely based on groups atm
  sevenDigitalUserId?: string;
  facilityId?: string;
  deviceId?: string;
  userType?: UserTypes;
  cognitoClientId?: string;
}

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private inactivatySubscription: Subscription;
  readonly onLogout = new Subject();
  readonly initialAuthState: AuthState = {
    isLoggedIn: false,
  };

  private readonly _authState = new BehaviorSubject<AuthState>({ ...this.initialAuthState });
  readonly authState$ = this._authState.asObservable();

  set authState(authState: AuthState) {
    this._authState.next(authState);
  }

  get authState() {
    return this._authState.value;
  }

  // get currentSession() {
  //   return from(Auth.currentSession()).pipe(
  //     catchError(error => {
  //       return of(false);
  //     })
  //   );
  // }

  constructor(
    private appState: AppStateService,
    private idleService: IdleService,
    private apiService: ApiService,
  ) {

    /**
     * In case we revoke the tokens for this user, we have to log them out
     */
    this.getAuthSession()
      .pipe(
        switchMap(res => {
          if (res && res.tokens) {
            return of(res);
          } else {
            return this.programmaticLogout()
          }
        }),
        catchError(err => of(err))

      ).subscribe();

    /**
     * We want to update the currentFacilityId in the idToken for SUPER_ADMINs
     */
    // console.log('SETTING UP CURRENT FACILITY SUBSCRIPTION');
    // this.appState.get$<string>('currentFacilityId')
    //   .pipe(
    //     tap(val => console.log('watching curent facilityid',val)),
    //     switchMap(facilityId => {
    //       console.log('^^^', this.appState.currentUser, facilityId);
    //       if (this.appState.currentUser && facilityId) {
    //         if (this.appState.currentUser.userType === UserTypes.SUPER_ADMIN) {
    //           return this.updateFacilityIdInToken(facilityId).pipe(map(res => facilityId))
    //         }
    //       }
    //       return of(facilityId)
    //     })
    //   )
    //   .subscribe();
  }

  /**
   * Passwordless login into Cognito.
   * USED BY: lockResident (resident.service.ts) and loginToken.guard
   * @param input Object with a challenge and username value
   * @returns
   */
  customChallengeLogin(input: { challenge: string, username: string, removeChallenge?: boolean }): Observable<{ status: string }> {
    const { challenge, username, removeChallenge } = input;

    return this.programmaticLogout({ clearState: false }).pipe(
      switchMap(() => this.signIn({ username })
        .pipe(
          switchMap(res => {
            if (res === 'CONFIRM_SIGN_IN_WITH_CUSTOM_CHALLENGE') {
              return from(confirmSignIn({ challengeResponse: challenge }))
                .pipe(
                  map(res => res.nextStep.signInStep)
                );
            } else {
              return of(res);
            }
          }),
          switchMap(status => {
            if (status === 'DONE') {
              return this.getAuthSession()
            }
            return undefined;
          }),
          map(authSession => {
            if (authSession) {
              const accessTokenPayload = authSession.tokens.accessToken.payload;
              const idTokenPayload = authSession.tokens.idToken.payload;
              this.setAuthState(idTokenPayload, accessTokenPayload);

              if (removeChallenge) {
                // remove challenge (self closing Observable)
                this.updateUserAttribute({
                  attributeKey: 'custom:authChallenge',
                  value: ''
                }).subscribe();
              }
              return { status: 'SUCCESS' }
            }
            return { status: 'FAIL' }
          }),
          catchError(error => {
            console.log(error);
            return of({
              status: 'ERROR'
            });
          })
        )))
  }

  verifyToken(input: { token: string }) {
    const statement = `
      query verifyAccessToken($input: VerifyAccessTokenInput!) {
        verifyAccessToken(input: $input) {
          status
          message
          payload
        }
      }
    `;
    return this.apiService
      .graphql<GenericResponse>({ statement, variables: { input }, type: 'verifyAccessToken', iam: true })
  }

  disableToken(input: { token: string }) {
    const statement = `
      mutation disableAccessToken($input: DisableAccessTokenInput!) {
        disableAccessToken(input: $input)
      }
    `;
    return this.apiService
      .graphql<GenericResponse>({ statement, variables: { input }, type: 'disableAccessToken' })
  }

  // THIS IS CURRENTLY HAVING AN ISSUE THAT FROM LOGIN TO DASHBOARD, there seem to be 2 instances of the
  // appstate. So the currentFacilityId$ is not being observed in the admin dashboard and it doesn't trigger
  // a correct update to a facilityId change.
  /**
   * Update the custom current facilityId attribute for SUPER_ADMIN users so we can verify
   * access in the API against this id. This attribute will be part of the idToken
   */
  // updateFacilityIdInToken(facilityId: string): Observable<boolean> {
  //   console.log('UPDATE FACILITY', facilityId);
  //   return from<Promise<CognitoUser>>(Auth.currentUserPoolUser()).pipe(
  //     switchMap(user => {
  //       return from(Auth.updateUserAttributes(user, {
  //         'custom:currentFacilityId': facilityId || ''
  //       })).pipe(
  //         map(val => true),
  //         catchError(err => this.programmaticLogout())
  //       )
  //     })
  //   );
  // }

  // function currently not in use but a could come in handy in the future
  // getCurrentDevice() {
  //   return from<Promise<CognitoUser>>(Auth.currentUserPoolUser()).pipe(
  //     switchMap(user => {

  //       user.getCachedDeviceKeyAndPassword()
  //       user.getDevice({
  //         onSuccess: function (result) {
  //           console.log('call result: ', result);
  //         },
  //         onFailure: function (err) {
  //           console.log('call error', err);
  //         }
  //       })
  //       return of(user);
  //     }))
  // }

  login(input: { username: string, password: string }): Observable<{ status: string }> {
    return this.signIn(input)
      .pipe(
        switchMap(res => {
          if (res === 'DONE') {
            return this.getAuthSession()
              .pipe(
                map(authSession => {
                  const { accessToken, idToken } = authSession.tokens;
                  const accessTokenPayload = accessToken.payload;
                  const idTokenPayload = idToken.payload;
                  const groups = idTokenPayload['cognito:groups'] as string[];
                  if (!groups.includes(UserTypes.SUPER_ADMIN)) {
                    this.logout().subscribe();
                    return { status: 'NO_ADMIN_PERMISSIONS' };
                  }
                  this.setAuthState(idTokenPayload, accessTokenPayload);
                  this.inactivityMonitor();
                  return { status: 'SUCCESS' }
                })
              )
          } else {
            return throwError(() => new Error('NOT_AUTHENTICATED'))
          }
        }),
        catchError(error => {
          return of({
            status: 'ERROR'
          });
        })
      );
  }

  passwordlessLogin(data: { username: string, tokenBody: string }): Observable<{ status: string }> {
    const { username } = this.authState;
    this.removeDeviceKey(username);

    const authChallenge = decodeURIComponent(data.tokenBody);
    return this.customChallengeLogin({
      username: data.username,
      challenge: authChallenge
    });
  }

  removeDeviceKey(username: string) {
    const { cognitoClientId } = this.authState;
    // remove device key because we removed the Cognito User in the backend
    window.localStorage.removeItem(`CognitoIdentityServiceProvider.${cognitoClientId}.${username}.deviceKey`);
  }

  programmaticLogout(params?: { clearState: boolean }) {
    const { clearState = true } = params || {};
    return this.signOut().pipe(
      tap(val => {
        if (clearState) {
          this.appState.clearState();
        }
        if (this.inactivatySubscription) {
          this.inactivatySubscription.unsubscribe();
        }
        // window.sessionStorage.removeItem(ViewModes.RESIDENT_VIEW);
        window.sessionStorage.removeItem('APOLLO_FACILITY_ID');
        // this.timeoutService.stopTimer();
      })
    );
  }

  /**
   * @description Full application logout, which will first get the current user object, attempts to empty custom:authChallenge
   * followed by a Signout. After that we clear session storage.
   * @returns boolean true if all went well
   */
  logout() {
    return this.getCurrentUser()
      .pipe(
        switchMap(res => {
          const { user } = res;
          if (user) {
            return this.updateUserAttribute({ attributeKey: 'custom:authChallenge', value: '' });
          }
          return of(res);
        }),
        switchMap(res => this.signOut()),
        tap(() => {
          this.appState.clearState();
          // window.sessionStorage.removeItem(ViewModes.RESIDENT_VIEW);
          window.sessionStorage.removeItem('APOLLO_FACILITY_ID');
          this.authState = { ...this.initialAuthState };
          if (this.inactivatySubscription) {
            this.inactivatySubscription.unsubscribe();
          }
          // this.timeoutService.stopTimer();
          // this.gtmService.pushEvent({
          //   event: 'logout'
          // });
          this.onLogout.next(null);
          this.onLogout.complete();
        }),
        map(val => true)
      )
  }

  // initiateResetPassword(username: string) {
  //   return from<Promise<CognitoUser>>(Auth.forgotPassword(username))
  //     .pipe(
  //       map(val => ({ status: 'success' })),
  //       catchError(error => {
  //         return of({
  //           status: 'error'
  //         });
  //       })
  //     );
  // }

  /**
   * This function checks if the cognito session is still active and pushes a next authState object.
   * Do NOT call this function outside the `auth.guard`, it can cause a authState conflict.
   */
  isLoggedIn(): Observable<AuthState | null> {
    return this.getAuthSession()
      .pipe(
        map(authSession => {
          if (authSession.tokens) {
            const { idToken, accessToken } = authSession.tokens;
            this.inactivityMonitor();
            const idTokenPayload = idToken.payload;
            const accessTokenPayload = accessToken.payload;
            this.setAuthState(idTokenPayload, accessTokenPayload);
            return this.authState;
          } else {
            return { ...this.initialAuthState };
          }
        })
      );
  }

  hasGroupAccess(groups: any): boolean {
    if (this.authState.groups && Array.isArray(groups)) {
      const match = getArrayMatchingValues(this.authState.groups, groups);
      if (!match.length) {
        return false
      }
      return true;
    }
    return true;
  }


  private signIn({ username, password }: loginInput): Observable<string> {
    let signInRes: Observable<SignInOutput>;
    if (password) {
      signInRes = from(signIn({ username, password }))
    } else {
      signInRes = from(signIn({
        username, options: {
          authFlowType: 'CUSTOM_WITHOUT_SRP'
        }
      }))
    }
    return signInRes
      .pipe(
        map(res => res.nextStep.signInStep),
        catchError((error: any) => {
          let response = 'UNKNOWN_ERROR';
          if (error.message === 'There is already a signed in user.') {
            return of('ALREADY_LOGGED_IN');
          } else if (error.message === 'Incorrect username or password.') {
            return of('INCORRECT_CREDENTIALS')
          }
          return of(response);
        })
      )
  }

  /**
   * @description Get the current Cognito AuthSession. Returns object with empty values if not authenticated
   * @returns Observable AuthSession
   */
  private getAuthSession() {
    return from(fetchAuthSession());
  }

  private signOut() {
    return from(signOut());
  }

  private updateUserAttribute({ attributeKey, value }: updateUserAttributeInput): Observable<boolean> {
    return from(updateUserAttribute({
      userAttribute: {
        attributeKey,
        value,
      }
    })
    ).pipe(
      map(res => {
        console.log('update attt', res);
        if (res.nextStep.updateAttributeStep === 'DONE') {
          return true;
        } else {
          // NEXT STEP HAS TO BE HANDLED
          return false;
        }
      })
    )
  }

  /**
   * @description gets username and userId. No network call (from session storage?)
   */
  private getCurrentUser(): Observable<{ user?: AuthUser, error?: string }> {
    return from(getCurrentUser())
      .pipe(
        catchError((error: any) => {
          let response = 'UNKNOWN_ERROR';
          if (error.message === 'User needs to be authenticated to call this API.') {
            response = 'NOT_AUTHENTICATED';
          }
          return of(response);
        }),
        map((res: AuthUser | string) => {
          if (typeof (res) === 'string') {
            return {
              error: res
            }
          } else {
            return {
              user: res
            }
          }
        })
      )
  }

  private setAuthState(idToken: any, accessToken: any): void {
    const groups = idToken['cognito:groups'];
    const userType = UserTypes.SUPER_ADMIN;

    const authState: AuthState = {
      isLoggedIn: true,
      username: idToken['cognito:username'],
      id: idToken.sub,
      groups,
      sevenDigitalUserId: idToken.sevenDigitalUserId,
      deviceId: accessToken.device_key,
      userType,
      cognitoClientId: idToken.aud,
    };


    this.appState.currentUser = {
      id: authState.id,
      username: authState.username,
      userType,
      permissions: groups as userPermissions[],
    }
    // push the authstate because someone might have refreshed the browser and then the authstate
    // would be in its initial state
    this.authState = authState;
  }

  /**
   * @description This function triggers on user input events and tries to determine if a user is logged out (lost jwt token)
   * and we should navigate back to the root folder
   */
  private inactivityMonitor() {
    // make sure we are not running multiple subscriptions
    if (this.inactivatySubscription) {
      this.inactivatySubscription.unsubscribe();
    }
    this.inactivatySubscription = this.idleService.eventObservables$
      .pipe(
        startWith(true),
        // try repond to less events (especially mousemoves)
        debounceTime(500),
        switchMap(event => this.getAuthSession().pipe(
          tap(authSession => {
            if (!authSession.tokens) {
              if (this.inactivatySubscription) {
                this.inactivatySubscription.unsubscribe();
              }
              // clear any session storage
              // this.appState.exitMode(ViewModes.RESIDENT_LOCK);
              // this.appState.exitMode(ViewModes.RESIDENT_VIEW);
              window.location.href = '/';
            }
            return of(true)
          })
        ))
      )
      .subscribe();
  }
}
