import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SafeHtml } from '@angular/platform-browser';
import { map, Subject, takeWhile, timer } from 'rxjs';

export interface DialogData {
  heading: string;
  body: string;
  // body: SafeHtml;
  ref: Subject<string>
}


@Component({
  selector: 'app-session-expiring-dialog',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatIconModule, AsyncPipe, NgIf],
  templateUrl: './session-expiring-dialog.component.html',
  styleUrls: ['./session-expiring-dialog.component.scss']
})
export class SessionExpiringDialogComponent {
  counter = 29;
  heading: string;
  body: string;
  counter$ = timer(0, 1000) //Initial delay 0 seconds and interval countdown also 1 second
    .pipe(
      takeWhile(() => this.counter >= 0),
      map((val) => {
        this.body = `${this.data.body}`.replace('%%SECONDS%%', this.counter.toString());
        this.ref.detectChanges();
        return this.counter--;
      }),
    ).subscribe();

  constructor(
    public dialogRef: MatDialogRef<SessionExpiringDialogComponent>,
    public ref: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    this.heading = data.heading;
    this.body = `${data.body}`;
  }


}
