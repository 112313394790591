<div class="flex flex-col">
  <div class="close">
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
  <div mat-dialog-header class="dark">
    <h2>{{ heading }}</h2>
  </div>
  <div mat-dialog-content>
    <div [innerHtml]="body"></div>
  </div>
  <div mat-dialog-content>
    <div class="flex flex-col">
      <button mat-stroked-button mat-dialog-close class="mb-4">
        <mat-icon color="primary">thumb_up</mat-icon>
        <span>Continue</span>
      </button>
    </div>
  </div>
</div>